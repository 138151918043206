import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageUploader from "components/image-upload/ImageUploader";

const LessonOverview = ({
  overview,
  errors,
  setFieldValue,
  deleteLesson,
  id,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-8 items-center w-full px-20">
      <div className="w-1/2 flex flex-col gap-2">
        <TextField
          size="small"
          value={overview?.title || ""}
          onChange={(e) => setFieldValue("title", e.target.value)}
          fullWidth
          label={t("lessons.title")}
          error={Boolean(errors?.title)}
          helperText={t(errors?.title)}
        />
        <TextField
          size="small"
          value={overview?.description || ""}
          onChange={(e) => setFieldValue("description", e.target.value)}
          fullWidth
          label={t("lessons.desc")}
          error={Boolean(errors?.description)}
          helperText={t(errors?.description)}
          maxRows={6}
          multiline
        />
        <TextField
          size="small"
          value={overview?.writer || ""}
          onChange={(e) => setFieldValue("writer", e.target.value)}
          fullWidth
          label={t("articles.writer")}
          error={Boolean(errors?.writer)}
          helperText={t(errors?.writer)}
          maxRows={6}
          multiline
        />

        {/* Flex container for both Autocompletes */}
        <div className="flex gap-4">
          <Autocomplete
            disablePortal
            options={[
              "Պատմություն",
              "Աշխարհագրություն",
              "Հայոց Լեզու",
              "Իմ Հայրենիքը",
            ]}
            sx={{ width: 300 }}
            value={overview?.subject || ""}
            onChange={(e, value) => setFieldValue("subject", value)}
            renderInput={(params) => <TextField {...params} label="Առարկա" />}
          />

          {/* New Autocomplete with multiple choices */}
          <Autocomplete
            multiple
            disablePortal
            options={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ]}
            sx={{ width: 300 }}
            value={overview?.lessonClass || []}
            onChange={(e, value) => setFieldValue("lessonClass", value)}
            renderInput={(params) => <TextField {...params} label="Դասարան" />}
          />
        </div>
      </div>

      <div className="flex gap-10">
        <ImageUploader
          text={t("lessons.cover")}
          image={overview?.images?.cover || ""}
          setImage={(file, url) =>
            setFieldValue("images", {
              ...overview.images,
              cover: file,
            })
          }
        />
        <ImageUploader
          text={t("lessons.poster")}
          image={overview?.images?.poster || ""}
          setImage={(file, url) =>
            setFieldValue("images", {
              ...overview.images,
              poster: file,
            })
          }
        />
      </div>
      {id && (
        <Button
          color="error"
          variant="contained"
          onClick={() => {
            // deleteLesson();
            setOpen(true);
            // navigate("/lessons");
          }}
        >
          Ջնջել Դասը
        </Button>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle> Հաստատում </DialogTitle>
        <DialogContent dividers>
          Դուք համոզված ե՞ք, որ դասը պետք է ջնջվի։
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Չեղարկել
          </Button>
          <Button
            onClick={() => {
              deleteLesson();
              setOpen(true);
              navigate("/lessons");
            }}
          >
            Ջնջել
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LessonOverview;
