import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "courses", id);
};

export const getData = async (reference) => {
  if (reference) {
    const docSnap = await getDoc(reference);
    return docSnap.exists ? { id: docSnap?.id, ...docSnap.data() } : null;
  }
  return null;
};

export const getCourses = async (last) => {
  try {
    // Query to get the courses
    const q = last
      ? query(
          collection(db, "courses"),
          orderBy("createdAt", "desc"),
          startAfter(last),
          limit(10),
        )
      : query(
          collection(db, "courses"),
          orderBy("createdAt", "desc"),
          limit(10),
        );

    const documentSnapshots = await getDocs(q);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    const courses = documentSnapshots.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    // Get the total count of courses in the collection
    const countQuery = query(collection(db, "courses"));
    const countSnapshot = await getCountFromServer(countQuery);
    const totalCoursesCount = countSnapshot.data().count;
    // Return courses, last document, and total count of courses
    return { courses, last: lastVisible, totalCoursesCount };
  } catch (error) {
    console.error(`Error while getting courses: ${error}`);
    return error;
  }
};

export const getCourseById = async (id) => {
  try {
    const docRef = getDocument(id); // Assumes `getDocument` fetches a course reference
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      return null; // Return null if the course document does not exist
    }
    const course = docSnap.data();
    return { id: docSnap.id, ...course, category: [], reviewers: [] };
  } catch (error) {
    console.error("Error fetching course and lessons:", error);
    throw error; // Rethrow the error for the caller to handle
  }
};

export const createCourse = async (course) => {
  try {
    const newCourse = await addDoc(collection(db, "courses"), course);
    return newCourse;
  } catch (e) {
    console.error(`Error while creating course ${e}`);
    return false;
  }
};

export const updateCourse = async (id, data) => {
  try {
    await updateDoc(getDocument(id), data);
  } catch (e) {
    console.error(`Error while updating course ${e}`);
    return false;
  }
};

export const deleteCourse = async (id) => {
  try {
    await deleteDoc(getDocument(id));
  } catch (e) {
    console.error(`Error while deleting course ${e}`);
    return false;
  }
};
