import * as Yup from "yup";

const URL_REGEX =
  // eslint-disable-next-line
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const articleFormSchema = () => {
  return Yup.object({
    title: Yup.string()
      .max(300, "myCourses.createCourse.validationErrors.invalid_title")
      .required("myCourses.createCourse.validationErrors.required"),
    writer: Yup.string().max(
      100,
      "myCourses.createCourse.validationErrors.invalid_title",
    ),
    description: Yup.string().max(
      1000,
      "myCourses.createCourse.validationErrors.invalid_overview",
    ),
    images: Yup.object().shape({
      cover: Yup.string(),
      poster: Yup.string(),
    }),
  });
};

const userFormSchema = () => {
  return Yup.object({
    displayName: Yup.string()
      .max(300, "myCourses.createCourse.validationErrors.invalid_title")
      .required("myCourses.createCourse.validationErrors.required"),
    about: Yup.string().max(
      1000,
      "myCourses.createCourse.validationErrors.invalid_overview",
    ),
    photoURL: Yup.string(),
    posterURL: Yup.string(),
  });
};

const groupFormSchema = () => {
  return Yup.object({
    title: Yup.string()
      .max(300, "myCourses.createCourse.validationErrors.invalid_title")
      .required("myCourses.createCourse.validationErrors.required"),
    goal: Yup.string().max(
      1000,
      "myCourses.createCourse.validationErrors.invalid_overview",
    ),
    images: Yup.object().shape({
      cover: Yup.string().required(
        "editor.video_editor.validationErrors.required",
      ),
      poster: Yup.string().required(
        "editor.video_editor.validationErrors.required",
      ),
    }),
  });
};

const presentationFormSchema = () => {
  return Yup.object({
    title: Yup.string()
      .max(300, "myCourses.createCourse.validationErrors.invalid_title")
      .required("myCourses.createCourse.validationErrors.required"),
    description: Yup.string().max(
      1000,
      "myCourses.createCourse.validationErrors.invalid_overview",
    ),
    slides: Yup.array(),
  });
};

const lessonFormSchema = () => {
  return Yup.object({
    title: Yup.string()
      .max(300, "myCourses.createCourse.validationErrors.invalid_title")
      .required("myCourses.createCourse.validationErrors.required"),
    description: Yup.string().max(
      1000,
      "myCourses.createCourse.validationErrors.invalid_overview",
    ),
    writer: Yup.string().max(
      100,
      "myCourses.createCourse.validationErrors.invalid_title",
    ),
    images: Yup.object().shape({
      cover: Yup.string(),
      poster: Yup.string(),
    }),
  });
};

const courseFormSchema = () => {
  return Yup.object({
    title: Yup.string()
      .max(300, "myCourses.createCourse.validationErrors.invalid_title")
      .required("myCourses.createCourse.validationErrors.required"),
    description: Yup.string().max(
      1000,
      "myCourses.createCourse.validationErrors.invalid_overview",
    ),
    writer: Yup.string().max(
      100,
      "myCourses.createCourse.validationErrors.invalid_title",
    ),
    images: Yup.object().shape({
      cover: Yup.string(),
      poster: Yup.string(),
    }),
  });
};

const quizSchema = () => {
  return Yup.object({
    questions: Yup.array()
      .of(
        Yup.object().shape({
          answers: Yup.array().of(
            Yup.string().required(
              "editor.video_editor.validationErrors.required",
            ),
          ),
          question: Yup.string().required(
            "editor.video_editor.validationErrors.required",
          ),
        }),
      )
      .optional(),
    description: Yup.string().max(500).optional(),
    position: Yup.object().required(
      "editor.video_editor.validationErrors.required",
    ),
    type: Yup.string().required(
      "editor.video_editor.validationErrors.required",
    ),
    link: Yup.string()
      .max(100)
      .optional()
      .matches(URL_REGEX, "modals.feedback.errors.invalid_url"),
  });
};

const eventFormSchema = () => {
  return Yup.object({
    title: Yup.string()
      .max(300, "myCourses.createCourse.validationErrors.invalid_title")
      .required("myCourses.createCourse.validationErrors.required"),
    description: Yup.string().max(
      1000,
      "myCourses.createCourse.validationErrors.invalid_overview",
    ),
    cover: Yup.string(),
  });
};

export {
  articleFormSchema,
  groupFormSchema,
  userFormSchema,
  presentationFormSchema,
  lessonFormSchema,
  courseFormSchema,
  quizSchema,
  eventFormSchema,
};
