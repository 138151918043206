import { useFormik } from "formik";
import { lessonFormSchema } from "libs/validation/schemas";
import mediaUploader from "api/mediaUploader.api";
import useAuth from "hooks/useAuth";

const FIELDS = {
  TITLE: "title",
  DESCRIPTION: "description",
  IMAGES: "images",
  CONTENT: "content",
  VIDEO: "video",
  SUMMARY: "summary",
  QUIZ: "quiz",
  SUBJECT: "subject",
  LESSON_CLASS: "lessonClass",
  WRITER: "writer",
};

const useLessonForm = (props) => {
  const { lesson, create, update, id } = props;
  const { currentUser } = useAuth();
  const lessonForm = useFormik({
    initialValues: {
      [FIELDS.TITLE]: lesson.title || "",
      [FIELDS.IMAGES]: lesson.images || {},
      [FIELDS.DESCRIPTION]: lesson.description || "",
      [FIELDS.CONTENT]: lesson.content || "",
      [FIELDS.VIDEO]: lesson.video || "",
      [FIELDS.QUIZ]: lesson.quiz || {
        questions: [],
        description: "",
        type: "",
      },
      [FIELDS.SUMMARY]: lesson.summary || {
        points: [],
      },
      [FIELDS.SUBJECT]: lesson.subject || "",
      [FIELDS.LESSON_CLASS]: lesson.lessonClass || [],
      [FIELDS.WRITER]: lesson.writer || "",
    },
    enableReinitialize: true,
    validationSchema: lessonFormSchema,
    onSubmit: async (values) => {
      let posterUrl = "";
      let coverUrl = "";
      let contentUrl = "";
      const slug = `${values[FIELDS.TITLE].toLowerCase().replaceAll(" ", "-")}`;
      if (typeof values[FIELDS.IMAGES]?.cover !== "string") {
        coverUrl = await mediaUploader(
          values[FIELDS.IMAGES]?.cover,
          `lessons/covers/${slug}`,
        );
      }
      if (typeof values[FIELDS.IMAGES]?.poster !== "string") {
        posterUrl = await mediaUploader(
          values[FIELDS.IMAGES]?.poster,
          `lessons/posters/${slug}`,
        );
      }

      if (typeof values[FIELDS.CONTENT]) {
        const blob = new Blob([values[FIELDS.CONTENT]], { type: "text/plain" });
        const file = new File([blob], `${slug}.txt`, { type: "text/plain" });
        contentUrl = await mediaUploader(file, `lessons/content/${slug}`);
      }

      create({
        ...values,
        images: { poster: posterUrl, cover: coverUrl },
        content: contentUrl,
        author: { id: currentUser?.id, displayName: currentUser.displayName },
        createdAt: Date.now(),
      });
      update(id, {
        ...values,
        images: {
          poster: posterUrl || lesson.images.poster,
          cover: coverUrl || lesson.images.cover,
        },
        content: contentUrl,
        updatedAt: Date.now(),
      });
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = lessonForm;

  return {
    lessonForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors: lessonForm.errors,
  };
};

export default useLessonForm;
