import React from "react";
import { useParams } from "react-router-dom";
import useCourse from "hooks/courses/useCourse";
import CourseView from "components/courses/CourseView";

const Course = ({ isEditable }) => {
  const { id } = useParams();
  const { current, isLoading } = useCourse({ id });
  return (
    <div>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <CourseView course={current} isEditable={isEditable} />
      )}
    </div>
  );
};

export default Course;
