import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { Button } from "@mui/material";
import DraggableList from "components/draggable/DraggableList";
import Template from "components/draggable/Template";
import useTrendingArticles from "hooks/trending/useTrendingArticles";
import TrendingArticlesDialog from "./TrendingArticlesDialog";

const TrendingArticles = ({
  items,
  id,
  label,
  isLoading,
  getNextPage,
  page,
  setPage,
  hasMoreItems,
  category,
  setCategory,
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { trendingArticles, updateTrendingArticles } = useTrendingArticles();
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    trendingArticles &&
      setSelectedItems(
        trendingArticles.find((i) => i.id === id)?.content || [],
      );
  }, [trendingArticles, id]);
  return (
    <div>
      <div className="my-6">
        <div className="flex justify-between">
          <span className="text-[20px] font-bold text-gray-600">{label}</span>
          {JSON.stringify(selectedItems) !==
            JSON.stringify(
              trendingArticles.find((i) => i.id === id)?.content,
            ) && (
            <Button
              onClick={() => updateTrendingArticles(id, selectedItems)}
              variant="contained"
              color="success"
              size="small"
            >
              <span className="text-white">Հրապարակել</span>
            </Button>
          )}
        </div>
        <div className="mt-10">
          <DraggableList
            list={selectedItems}
            handleStateChange={(newOrder) => setSelectedItems(newOrder)}
            deleteItem={(id) =>
              setSelectedItems(selectedItems.filter((i) => i.id !== id))
            }
            template={<Template />}
          />
          <div className="mt-6 w-full flex items-center justify-center">
            <FaPlus
              onClick={() => setIsOpenDialog(true)}
              className="cursor-pointer"
              size={30}
              color="#7F7F7F"
            />
          </div>
        </div>
      </div>

      <TrendingArticlesDialog
        id={id}
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        items={items}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        isLoading={isLoading}
        hasMoreItems={hasMoreItems}
        getNextPage={getNextPage}
        page={page}
        setPage={setPage}
        label={label}
        category={category}
        setCategory={setCategory}
      />
    </div>
  );
};

export default TrendingArticles;
