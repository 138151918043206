import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCourses,
  createCourse,
  updateCourse,
  deleteCourse,
  getCourseById,
} from "api/courses.api";

export const getCoursesAsync = createAsyncThunk("courses/get-all", async () => {
  const data = await getCourses();
  return data;
});

export const getNextCoursesAsync = createAsyncThunk(
  "courses/get-all-next",
  async (last) => {
    const data = await getCourses(last);
    return data;
  },
);

export const getCourseByIdAsync = createAsyncThunk(
  "course/get-by-id",
  async (id) => {
    const course = await getCourseById(id);
    const responseGoal = course.goal && (await fetch(course.goal));
    const textGoal = responseGoal ? await responseGoal?.text() : "";
    return {
      ...course,
      goal: textGoal,
    };
  },
);

export const createCourseAsync = createAsyncThunk(
  "courses/create",
  async (course) => {
    const courses = await createCourse(course);
    return courses;
  },
);

export const updateCourseAsync = createAsyncThunk(
  "course/update",
  async ({ id, data }) => {
    await updateCourse(id, data);
  },
);

export const deleteCourseAsync = createAsyncThunk(
  "course/delete",
  async (id) => {
    await deleteCourse(id);
  },
);
