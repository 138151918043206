import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import MainContentList from "components/main-content/MainContentList";

const categories = [
  { value: "Պատմություն", label: "Պատմություն" },
  { value: "Աշխարհագրություն", label: "Աշխարհագրություն" },
  { value: "Հայոց Լեզու", label: "Հայոց Լեզու" },
  { value: "Իմ Հայրենիքը", label: "Իմ Հայրենիքը" },
];

const MainContentDialog = ({
  items,
  selectedItems,
  setSelectedItems,
  open,
  onClose,
  isLoading,
  getNextPage,
  page,
  setPage,
  hasMoreItems,
  label,
  currentUser,
  filters,
  setFilters = () => {},
}) => {
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle>
        <div className="flex justify-between">
          {label}
          <Button onClick={onClose}>
            <IoClose color="gray" size={30} />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          multiple
          disablePortal
          size="small"
          options={[
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ]}
          sx={{ width: 300 }}
          value={filters?.lessonClass || []}
          onChange={(e, value) =>
            setFilters((prev) =>
              prev.lessonClass?.includes(value)
                ? {
                    ...prev,
                    lessonClass: prev.lessonClass.filter((i) => i !== value),
                  }
                : { ...prev, lessonClass: value },
            )
          }
          renderInput={(params) => <TextField {...params} label="Դասարան" />}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.authorId === currentUser?.id}
              color="primary"
              onChange={(e) =>
                setFilters((prev) =>
                  prev.authorId === e.target.value
                    ? { ...prev, authorId: "" }
                    : { ...prev, authorId: e.target.value },
                )
              }
              value={currentUser?.id}
            />
          }
          label="Միայն իմը"
        />
        {categories.map((cat) => {
          return (
            <FormControlLabel
              key={cat.value}
              control={
                <Checkbox
                  checked={filters.subject === cat.value}
                  color="primary"
                  onChange={(e) =>
                    setFilters((prev) =>
                      prev.subject === e.target.value
                        ? { ...prev, subject: "" }
                        : { ...prev, subject: e.target.value },
                    )
                  }
                  value={cat.value}
                />
              }
              label={cat.label}
            />
          );
        })}
        <MainContentList
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          items={items}
          isLoadingNextPage={isLoading}
          hasMoreItems={hasMoreItems}
          getNextPage={getNextPage}
          page={page}
          setPage={setPage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MainContentDialog;
