import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCourses,
  selectIsLoading,
  selectLastCourse,
  selectCoursesCount,
} from "redux-toolkit/courses/selectors";
import {
  getCoursesAsync,
  getNextCoursesAsync,
} from "redux-toolkit/courses/actions";

const useCourses = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const courses = useSelector(selectCourses);
  const isLoading = useSelector(selectIsLoading);
  const last = useSelector(selectLastCourse);
  const count = useSelector(selectCoursesCount);

  useEffect(() => {
    dispatch(getCoursesAsync());
  }, [dispatch]);

  const getNextPage = () => {
    if (last && count > courses.length) {
      dispatch(getNextCoursesAsync(last));
      setPage(page + 1);
    }
  };

  return {
    courses,
    isLoading,
    hasMoreItems: count > courses.length,
    page,
    setPage,
    getNextPage,
  };
};

export default useCourses;
