import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import DraggableList from "components/draggable/DraggableList";
import Template from "components/draggable/Template";
import useLessons from "hooks/lessons/useLessons";
import MainContentDialog from "components/main-content/MainContentDialog";

const MainContent = ({
  selectedItems = [],
  id,
  label,
  setSelectedItems,
  currentUser,
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [filters, setFilters] = useState({});
  const { lessons, isLoading, getNextPage, page, setPage, hasMoreItems } =
    useLessons(filters);

  return (
    <div>
      <div className="my-6">
        <div className="flex justify-between">
          <span className="text-[20px] font-bold text-gray-600">{label}</span>
        </div>
        <div className="mt-10">
          <DraggableList
            list={selectedItems}
            handleStateChange={(newOrder) => setSelectedItems(newOrder)}
            deleteItem={(id) =>
              setSelectedItems(selectedItems.filter((i) => i.id !== id))
            }
            template={<Template />}
          />
          <div className="mt-6 w-full flex items-center justify-center">
            <FaPlus
              onClick={() => setIsOpenDialog(true)}
              className="cursor-pointer"
              size={30}
              color="#7F7F7F"
            />
          </div>
        </div>
      </div>

      <MainContentDialog
        id={id}
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        items={lessons}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        isLoading={isLoading}
        hasMoreItems={hasMoreItems}
        getNextPage={getNextPage}
        page={page}
        setPage={setPage}
        label={label}
        currentUser={currentUser}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  );
};

export default MainContent;
