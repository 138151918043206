import React from "react";
import { MdOutlineDragIndicator, MdDelete } from "react-icons/md";
import { Button } from "@mui/material";

const Template = ({ item, provided, deleteItem }) => {
  const { id, title, description } = item;
  return (
    <div
      className="flex justify-between mt-4 between bg-white shadow-lg items-center rounded-md group"
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <div {...provided.dragHandleProps} className="max-w-4 mt-2 mx-2">
        <MdOutlineDragIndicator size={20} />
      </div>
      <div className="relative flex w-full py-4 hover:cursor-pointer">
        <div className="flex flex-col px-6">
          <span className="font-bold text-sm line-clamp-2 mt-2 hover:underline">
            {title}
          </span>
          <span className="text-sm line-clamp-2">{description}</span>
        </div>
      </div>
      <div className="flex max-w-4 items-center items-center cursor-pointer opacity-0 group-hover:opacity-100">
        <Button
          onClick={() => {
            deleteItem(id);
          }}
        >
          <MdDelete color="red" size={20} />
        </Button>
      </div>
    </div>
  );
};

export default Template;
