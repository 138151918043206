import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
} from "@mui/material";
import LessonList from "components/lessons/LessonList";
import useLessons from "hooks/lessons/useLessons";
import Loading from "components/common/Loading";
import useAuth from "hooks/useAuth";

const categories = [
  { value: "Պատմություն", label: "Պատմություն" },
  { value: "Աշխարհագրություն", label: "Աշխարհագրություն" },
  { value: "Հայոց Լեզու", label: "Հայոց Լեզու" },
  { value: "Իմ Հայրենիքը", label: "Իմ Հայրենիքը" },
];

const Articles = () => {
  const [filters, setFilters] = useState({});
  const { lessons, isLoading, getNextPage, page, setPage, hasMoreItems } =
    useLessons(filters);
  const { currentUser } = useAuth();
  return isLoading && lessons.length === 0 ? (
    <Loading />
  ) : (
    <div>
      <div className="px-8 pt-10">
        <Autocomplete
          multiple
          disablePortal
          size="small"
          options={[
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
          ]}
          sx={{ width: 300 }}
          value={filters?.lessonClass || []}
          onChange={(e, value) =>
            setFilters((prev) =>
              prev.lessonClass?.includes(value)
                ? {
                    ...prev,
                    lessonClass: prev.lessonClass.filter((i) => i !== value),
                  }
                : { ...prev, lessonClass: value },
            )
          }
          renderInput={(params) => <TextField {...params} label="Դասարան" />}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filters.authorId === currentUser?.id}
              color="primary"
              onChange={(e) =>
                setFilters((prev) =>
                  prev.authorId === e.target.value
                    ? { ...prev, authorId: "" }
                    : { ...prev, authorId: e.target.value },
                )
              }
              value={currentUser?.id}
            />
          }
          label="Միայն իմը"
        />
        {categories.map((cat) => {
          return (
            <FormControlLabel
              key={cat.value}
              control={
                <Checkbox
                  checked={filters.subject === cat.value}
                  color="primary"
                  onChange={(e) =>
                    setFilters((prev) =>
                      prev.subject === e.target.value
                        ? { ...prev, subject: "" }
                        : { ...prev, subject: e.target.value },
                    )
                  }
                  value={cat.value}
                />
              }
              label={cat.label}
            />
          );
        })}
      </div>
      <LessonList
        lessons={lessons}
        currentUserId={currentUser?.id}
        currentUserType={currentUser?.type}
        isLoadingNextPage={isLoading}
        hasMoreItems={hasMoreItems}
        getNextPage={getNextPage}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default Articles;
