import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  startAfter,
  getCountFromServer,
  where,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "lessons", id);
};

export const getData = async (reference) => {
  if (reference) {
    const docSnap = await getDoc(reference);
    return docSnap.exists ? { id: docSnap?.id, ...docSnap.data() } : null;
  }
  return null;
};

// export const getLessons = async () => {
//   const q = query(collection(db, "lessons"), limit(12));
//   const querySnapshot = await getDocs(q);
//   const lessons = querySnapshot.docs.map((doc) => {
//     return { ...doc.data(), category: [], reviewers: [], id: doc.id };
//   });
//   return lessons;
// };

export const getLessons = async (last, filters = {}) => {
  try {
    // Build the base query
    let q = query(
      collection(db, "lessons"),
      orderBy("createdAt", "desc"),
      ...(last ? [startAfter(last)] : []),
      limit(10),
    );

    // Conditionally add filter clauses
    if (filters.subject) {
      q = query(q, where("subject", "==", filters.subject));
    }
    if (!!filters.lessonClass?.length) {
      q = query(
        q,
        where("lessonClass", "array-contains-any", filters.lessonClass),
      );
    }
    if (filters.authorId) {
      q = query(q, where("author.id", "==", filters.authorId));
    }

    const documentSnapshots = await getDocs(q);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    const lessons = documentSnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    // Build the query for the total count
    let queryCount = query(
      collection(db, "lessons"),
      orderBy("createdAt", "desc"),
    );

    if (filters.subject) {
      queryCount = query(queryCount, where("subject", "==", filters.subject));
    }
    if (!!filters.lessonClass?.length) {
      queryCount = query(
        queryCount,
        where("lessonClass", "array-contains-any", filters.lessonClass),
      );
    }
    if (filters.authorId) {
      queryCount = query(
        queryCount,
        where("author.id", "==", filters.authorId),
      );
    }

    const countSnapshot = await getCountFromServer(queryCount);
    const totalLessonsCount = countSnapshot.data().count;

    // Return lessons, last document, and total count of lessons
    return { lessons, last: lastVisible, totalLessonsCount };
  } catch (error) {
    console.error(`Error while getting lessons: ${error}`);
    return error;
  }
};

export const getLessonById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const lesson = docSnap.data();
  return docSnap.exists
    ? { id: docSnap.id, ...lesson, category: [], reviewers: [] }
    : null;
};

export const createLesson = async (lesson) => {
  try {
    await addDoc(collection(db, "lessons"), lesson);
    return lesson;
  } catch (e) {
    console.error(`Error while creating lesson ${e}`);
    return false;
  }
};

export const updateLessons = async (id, data) => {
  try {
    await updateDoc(getDocument(id), data);
  } catch (e) {
    console.error(`Error while updating lesson ${e}`);
    return false;
  }
};

export const deleteLesson = async (id) => {
  try {
    await deleteDoc(getDocument(id));
  } catch (e) {
    console.error(`Error while deleting lesson ${e}`);
    return false;
  }
};
