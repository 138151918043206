import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentLesson,
  selectIsLoading,
} from "redux-toolkit/lessons/selectors";
import {
  getLessonByIdAsync,
  createLessonAsync,
  updateLessonAsync,
  deleteLessonAsync,
} from "redux-toolkit/lessons/actions";

const useLesson = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentLesson);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getLessonByIdAsync(id));
  }, [dispatch, id]);

  const createLesson = useCallback(
    (newLesson) => {
      dispatch(createLessonAsync(newLesson));
    },
    [dispatch],
  );

  const updateLesson = useCallback(
    (id, data) => {
      dispatch(updateLessonAsync({ id, data }));
    },
    [dispatch],
  );

  const deleteLesson = useCallback(() => {
    dispatch(deleteLessonAsync(id));
  }, [dispatch]);

  return {
    current,
    isLoading,
    createLesson,
    updateLesson,
    deleteLesson,
  };
};

export default useLesson;
