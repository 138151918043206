import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Animate } from "react-animate-mount";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Stepper from "components/stepper/Stepper";
import LessonOverview from "components/lessons/LessonOverview";
import TextEditor from "components/textEditor/TextEditor";
import useLesson from "hooks/lessons/useLesson";
import useLessonForm from "hooks/lessons/useLessonForm";
import LessonView from "components/lessons/LessonView";
import QuizForm from "components/quiz/QuizForm";
import VideoInput from "components/lessons/VideoInput";
import LessonSummaryForm from "components/lessons/summary/LessonSummaryForm";

const ManageLesson = ({ isEditable }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const { current, updateLesson, createLesson, deleteLesson } = useLesson({
    id,
  });
  const { errors, FIELDS, values, setFieldValue, handleSubmit } = useLessonForm(
    {
      id,
      lesson: current || {},
      create: !id ? createLesson : () => {},
      update: id ? updateLesson : () => {},
    },
  );
  useEffect(() => {
    setIsMounted(true);
  }, []);
  const steps = [
    {
      id: 1,
      title: "Step 1",
      desc: t("lessons.general"),
      content: (
        <LessonOverview
          errors={{
            title: errors[FIELDS.TITLE],
            description: errors[FIELDS.DESCRIPTION],
            images: errors[FIELDS.IMAGES],
            writer: errors[FIELDS.WRITER],
          }}
          overview={{
            title: values[FIELDS.TITLE],
            description: values[FIELDS.DESCRIPTION],
            images: values[FIELDS.IMAGES],
            subject: values[FIELDS.SUBJECT],
            lessonClass: values[FIELDS.LESSON_CLASS],
            writer: values[FIELDS.WRITER],
          }}
          setFieldValue={setFieldValue}
          deleteLesson={deleteLesson}
          id={id}
        />
      ),
    },
    {
      id: 2,
      title: "Step 2",
      desc: t("lessons.content"),
      content: (
        <div className="pb-20">
          <TextEditor
            isEditable={isEditable}
            setValue={(value) => {
              setFieldValue(FIELDS.CONTENT, value);
            }}
            value={values[FIELDS.CONTENT]}
            height="100%"
            width="100%"
          />
        </div>
      ),
    },
    {
      id: 3,
      title: "Step 3",
      desc: t("lessons.videoLesson"),
      content: (
        <div className="pb-20">
          <VideoInput
            isEditable={isEditable}
            setValue={(value) => {
              setFieldValue(FIELDS.VIDEO, value);
            }}
            video={values[FIELDS.VIDEO]}
            height="100%"
            width="100%"
          />
        </div>
      ),
    },
    {
      id: 4,
      title: "Step 4",
      desc: "Ամփոփում",
      content: (
        <div className="pb-20">
          <LessonSummaryForm
            currentSummary={values[FIELDS.SUMMARY]}
            setCurrentSummary={(value) => setFieldValue(FIELDS.SUMMARY, value)}
          />
        </div>
      ),
    },
    {
      id: 5,
      title: "Step 5",
      desc: "Հարցաշար",
      content: (
        <div className="pb-20">
          <QuizForm
            currentQuiz={values[FIELDS.QUIZ]}
            setCurrentQuiz={(value) => setFieldValue(FIELDS.QUIZ, value)}
          />
        </div>
      ),
    },
    {
      id: 6,
      title: "Step 6",
      desc: t("lessons.review"),
      content: <LessonView isEditable={isEditable} lesson={values} />,
    },
  ];

  return (
    <div className="mt-10 flex flex-col justify-center">
      <Animate show={isMounted}>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isWithErrors={Object.keys(errors).length}
        />
      </Animate>
      <div className="flex w-full justify-between mt-10">
        {activeStep < steps.length - 1 && (
          <div
            onClick={() => activeStep > 0 && setActiveStep(activeStep - 1)}
            className={`p-2 bg-opacity-50 z-50 flex items-center ${
              activeStep > 0 ? "opacity-100 cursor-pointer" : "opacity-0"
            }`}
          >
            <FaChevronLeft size={32} color="gray" />
          </div>
        )}
        <div className="w-full">
          {steps.map((step, index) => {
            return (
              <Animate show={index === activeStep} key={index}>
                {step.content}
              </Animate>
            );
          })}
        </div>
        {activeStep <= 5 &&
          !Object.keys(errors).length &&
          values[FIELDS.TITLE] && (
            <div
              onClick={() => {
                if (activeStep === 5) {
                  setActiveStep(activeStep + 1);
                  handleSubmit();
                } else {
                  setActiveStep(activeStep + 1);
                }
              }}
              className={`p-2 bg-opacity-50 cursor-pointer flex justify-end items-center ${
                steps.length !== activeStep ? "opacity-100" : "opacity-0"
              }`}
            >
              <FaChevronRight size={32} color="gray" />
            </div>
          )}
      </div>
    </div>
  );
};

export default ManageLesson;
