import Dashboard from "../../pages/dashboard/Dashboard";
import Login from "../../pages/login/LoginPage";
import Register from "components/auth/RegisterPage";
import NotFound from "../main/NotFound";
import Articles from "pages/articles/Articles";
import ManageArticle from "pages/articles/manage/ManageArticle";
import Article from "pages/articles/Article";
import Groups from "pages/groups/Groups";
import Group from "pages/groups/Group";
import EditGroup from "pages/groups/manage/EditGroup";
import EditPresentation from "pages/presentations/manage/EditPresentation";
import Presentations from "pages/presentations/Presentations";
import Lessons from "pages/lessons/Lessons";
import ManageLesson from "pages/lessons/manage/ManageLesson";
import Lesson from "pages/lessons/Lesson";
import Presentation from "pages/presentations/Presentation";
import Profile from "pages/profile/Profile";
import Events from "pages/events/Events";
import ManageEvent from "pages/events/manage/ManageEvent";
import Courses from "pages/courses/Courses";
import Course from "pages/courses/Course";
import ManageCourse from "pages/courses/manage/ManageCourse";
import Trending from "pages/trending/Trending";

export const protectedRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/profile", element: <Profile /> },
  { path: "/profile/:id", element: <Profile /> },
  { path: "/articles", element: <Articles /> },
  { path: "/articles/:id", element: <Article isEditable={false} /> },
  { path: "/articles/manage", element: <ManageArticle isEditable={true} /> },
  {
    path: "/articles/manage/:id",
    element: <ManageArticle isEditable={true} />,
  },
  { path: "/presentations", element: <Presentations /> },
  {
    path: "/groups",
    element: <Groups />,
  },
  { path: "/groups/manage", element: <EditGroup /> },
  { path: "/groups/manage/:id", element: <EditGroup /> },
  { path: "/groups/:id", element: <Group /> },
  { path: "/presentations/:id", element: <Presentation />, hideWrapper: true },
  { path: "/presentations", element: <EditPresentation /> },
  { path: "/presentations/manage", element: <EditPresentation /> },
  { path: "/presentations/manage/:id", element: <EditPresentation /> },
  { path: "/school", element: <div> School </div> },
  { path: "/courses", element: <Courses /> },
  { path: "/courses/:id", element: <Course isEditable={false} /> },
  { path: "/courses/manage", element: <ManageCourse isEditable={true} /> },
  { path: "/courses/manage/:id", element: <ManageCourse isEditable={true} /> },
  { path: "/lessons", element: <Lessons /> },
  { path: "/lessons/:id", element: <Lesson isEditable={false} /> },
  { path: "/lessons/manage", element: <ManageLesson isEditable={true} /> },
  {
    path: "/lessons/manage/:id",
    element: <ManageLesson isEditable={true} />,
  },
  { path: "/library", element: <div> Library </div> },
  { path: "/events", element: <Events /> },
  { path: "/events/manage", element: <ManageEvent isEditable={true} /> },
  {
    path: "/events/manage/:id",
    element: <ManageEvent isEditable={true} />,
  },
  { path: "/trending", element: <Trending /> },
  { path: "*", element: <NotFound /> },
];

export const routes = [
  { path: "/login", element: <Login /> },
  { path: "/register", element: <Register /> },
  { path: "*", element: <NotFound /> },
];
