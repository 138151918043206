import React from "react";
import { useTranslation } from "react-i18next";
import InfiniteScrollTrigger from "components/infinite-scroll/InfiniteScrollTrigger";
import Card from "components/card/Card";
import { skeletonGenerator } from "components/infinite-scroll/helpers";
import ProgramSkeleton from "components/infinite-scroll/skeletons/ArticleSkeleton";

const TrendingArticlesList = ({
  selectedItems,
  setSelectedItems,
  items,
  isLoadingNextPage,
  hasMoreItems,
  getNextPage,
  page,
  setPage,
}) => {
  return (
    <div
      className="w-full h-full overflow-y-auto"
      style={{
        background: "#e2e2e2",
      }}
    >
      <div className="m-auto">
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
          {items.map((item) => {
            const { title, images, description, id, author, categories } = item;
            const isSelected = selectedItems.find((i) => i.id === id);
            return (
              <Card
                key={id}
                title={title}
                image={images.cover}
                categories={categories}
                description={description}
                primaryButtonText={isSelected ? "Հեռացնել" : "Ավելացնել"}
                showSecondaryButton={false}
                onPrimaryButtonClick={() => {
                  isSelected
                    ? setSelectedItems(selectedItems.filter((i) => i.id !== id))
                    : setSelectedItems([...selectedItems, item]);
                }}
                author={author}
                primaryButtonColor={isSelected ? "error" : "primary"}
              />
            );
          })}
          {isLoadingNextPage && skeletonGenerator(<ProgramSkeleton />)}
          <InfiniteScrollTrigger
            isLoading={isLoadingNextPage}
            hasMoreItems={hasMoreItems}
            callback={getNextPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TrendingArticlesList;
