import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentCourse,
  selectIsLoading,
} from "redux-toolkit/courses/selectors";
import {
  getCourseByIdAsync,
  createCourseAsync,
  updateCourseAsync,
  deleteCourseAsync,
} from "redux-toolkit/courses/actions";

const useCourse = ({ id }) => {
  const dispatch = useDispatch();
  const current = useSelector(selectCurrentCourse);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    id && dispatch(getCourseByIdAsync(id));
  }, [dispatch, id]);

  const createCourse = useCallback(
    (newCourse) => {
      dispatch(createCourseAsync(newCourse));
    },
    [dispatch],
  );

  const updateCourse = useCallback(
    (id, data) => {
      dispatch(updateCourseAsync({ id, data }));
    },
    [dispatch],
  );

  const deleteCourse = useCallback(() => {
    dispatch(deleteCourseAsync(id));
  }, [dispatch]);

  return {
    current,
    isLoading,
    createCourse,
    updateCourse,
    deleteCourse,
  };
};

export default useCourse;
