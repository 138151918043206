import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ImageUploader from "components/image-upload/ImageUploader";
import Quiz from "components/quiz/Quiz";
import CourseSummary from "components/courses/summary/CourseSummary";
import "../../../node_modules/video-react/dist/video-react.css"; // import css
import TextEditor from "components/textEditor/TextEditor";

const CourseView = ({ course, isEditable }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const coverUrl =
    course?.images?.cover &&
    (typeof course?.images?.cover === "string"
      ? course?.images?.cover
      : URL.createObjectURL(course?.images?.cover));
  const posterUrl =
    course?.images?.poster &&
    (typeof course?.images?.poster === "string"
      ? course?.images?.poster
      : URL.createObjectURL(course?.images?.poster));

  return (
    <div className="h-full bg-cover bg-center mb-40 group">
      {posterUrl && (
        <div className="relative">
          <ImageUploader
            isDisabled={!isEditable}
            imageComponent={
              <div
                className="relative w-full h-64 bg-black bg-opacity-50 hover:bg-gray-800"
                style={{ backgroundImage: `url(${posterUrl})` }}
              />
            }
            text={t("courses.cover")}
            image={course?.images?.poster || ""}
            setImage={(file, url) => {}}
          />
          {coverUrl && (
            <div className="absolute right-20 -bottom-20">
              <ImageUploader
                isDisabled={!isEditable}
                imageComponent={
                  <img
                    alt="group cover"
                    src={coverUrl}
                    className="rounded-md h-40 w-40"
                  />
                }
                text={t("courses.poster")}
                image={coverUrl}
                setImage={(file, url) => {}}
              />
              <Typography
                className="mt-4"
                gutterBottom
                variant="text"
                component="div"
                color="textSecondary"
              >
                {course?.subject}
              </Typography>
            </div>
          )}
        </div>
      )}
      <div className="px-10">
        <div className="w-full h-24 bg-white flex flex-row">
          <div className="w-3/4 my-auto">
            <Typography gutterBottom variant="h3" component="div">
              {course?.title}
            </Typography>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab disabled={!course?.description}>Ընդհանուր</Tab>
            <Tab disabled={!course?.goal}>Նպատակ</Tab>
            <Tab disabled={!course?.plan}>Թեմատիկ պլան</Tab>
            <Tab disabled={!course?.content}>Բովանդակություն</Tab>
            <Tab disabled={!course?.summary}>Ամփոփում</Tab>
            <Tab disabled={!course?.quiz}>Հարցում</Tab>
          </TabList>
          <TabPanel>
            <div className="overflow-y-visible mb-24 mt-20">
              <Typography
                className="text-center w-3/4 mx-auto"
                gutterBottom
                variant="h4"
                component="div"
              >
                {course?.description}
              </Typography>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="w-full">
              {course?.goal && (
                <TextEditor
                  isEditable={isEditable}
                  value={course?.goal}
                  height="100%"
                  width="100%"
                />
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="w-full">
              {course?.plan && (
                <iframe
                  width="100%"
                  height="660px"
                  src={course?.plan}
                  title="PDF Plan"
                  allowFullScreen
                />
              )}
            </div>
          </TabPanel>

          <TabPanel>
            <div className="w-full">
              {course?.lessons?.map((lesson) => (
                <div className="relative flex w-full py-4 hover:cursor-pointer">
                  <div className="flex flex-col px-6">
                    <span
                      className="font-bold text-sm line-clamp-2 mt-2 hover:underline"
                      onClick={() => navigate(`/lessons/${lesson.id}`)}
                    >
                      {lesson.title}
                    </span>
                    <span className="text-sm line-clamp-2">
                      {lesson.description}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel>
            {course?.summary && <CourseSummary summary={course.summary} />}
          </TabPanel>
          <TabPanel>{course?.quiz && <Quiz quiz={course.quiz} />}</TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default CourseView;
