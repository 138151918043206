import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";

export default function MediaCard({
  image,
  title,
  subject,
  lessonClass,
  author = {},
  primaryButtonText = "PBText",
  secondaryButtonText = "SBText",
  onPrimaryButtonClick = () => {},
  onSecondaryButtonClick = () => {},
  category = null,
  showPrimaryButton = true,
  showSecondaryButton = true,
  primaryButtonColor = "primary",
}) {
  const profileLink = `/profile/${author?.id}`;
  return (
    <Card className="flex flex-col" sx={{ maxWidth: 345, height: "100%" }}>
      <CardMedia sx={{ height: 180 }} image={image} title={title} />
      <CardContent sx={{ flexGrow: 1 }}>
        {category && (
          <Typography className="text-wrap mb-2" variant="body2" color="purple">
            {category}
          </Typography>
        )}
        {subject && (
          <Typography className="text-wrap mb-2" variant="body2" color="purple">
            {subject}
          </Typography>
        )}
        {lessonClass && (
          <Typography className="text-wrap mb-2" variant="body2" color="green">
            {`Դասարան՝ ${lessonClass.join(", ")}`}
          </Typography>
        )}
        <Typography gutterBottom variant="subtitle2" component="div">
          {title}
        </Typography>
        {author && (
          <div className="mt-2 w-full flex flex-row">
            <Typography
              variant="body2"
              color="text.secondary"
              className="text-right"
            >
              {"Հեղինակ` "}
            </Typography>
            <Link
              className="ml-2"
              variant="subtitle2"
              component={RouterLink}
              to={profileLink}
            >
              {author?.displayName}
            </Link>
          </div>
        )}
      </CardContent>
      <CardActions className="b-0" sx={{ marginTop: "auto" }}>
        {showPrimaryButton && (
          <Button
            color={primaryButtonColor}
            size="small"
            onClick={onPrimaryButtonClick}
          >
            {primaryButtonText}
          </Button>
        )}
        {showSecondaryButton && (
          <Button size="small" onClick={onSecondaryButtonClick}>
            {secondaryButtonText}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
