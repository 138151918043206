import React from "react";
import CourseList from "components/courses/CourseList";
import useCourses from "hooks/courses/useCourses";
import Loading from "components/common/Loading";
import useAuth from "hooks/useAuth";

const Articles = () => {
  const { courses, isLoading, getNextPage, page, setPage, hasMoreItems } =
    useCourses();
  const { currentUser } = useAuth();
  return isLoading && courses.length === 0 ? (
    <Loading />
  ) : (
    <CourseList
      courses={courses}
      currentUserId={currentUser?.id}
      currentUserType={currentUser?.type}
      isLoadingNextPage={isLoading}
      hasMoreItems={hasMoreItems}
      getNextPage={getNextPage}
      page={page}
      setPage={setPage}
    />
  );
};

export default Articles;
