import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { IoClose } from "react-icons/io5";
import TrendingArticlesList from "./TrendingArticlesList";

const TrendingArticlesDialog = ({
  items,
  selectedItems,
  setSelectedItems,
  open,
  onClose,
  isLoading,
  getNextPage,
  page,
  setPage,
  hasMoreItems,
  label,
}) => {
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle>
        <div className="flex justify-between">
          {label}
          <Button onClick={onClose}>
            <IoClose color="gray" size={30} />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <TrendingArticlesList
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          items={items}
          isLoadingNextPage={isLoading}
          hasMoreItems={hasMoreItems}
          getNextPage={getNextPage}
          page={page}
          setPage={setPage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TrendingArticlesDialog;
