import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTrendingArticles, updateTrendingArticles } from "api/trending.api";

export const getTrendingArticlesAsync = createAsyncThunk(
  "trending/get-all",
  async () => {
    const articles = await getTrendingArticles();
    return articles;
  },
);

export const updateTrendingArticlesAsync = createAsyncThunk(
  "trending/update",
  async ({ id, data }) => {
    await updateTrendingArticles(id, data);
  },
);
