import React from "react";
import useArticles from "hooks/articles/useArticles";
import TrendingArticles from "components/trending/TrendingArticles";

const Trending = () => {
  const { articles, isLoading, getNextPage, page, setPage, hasMoreItems } =
    useArticles();
  return (
    <div className="mb-10">
      <div className="p-16">
        <TrendingArticles
          id="articles"
          items={articles}
          label="Արդիական Հոդվածներ"
          isLoading={isLoading}
          hasMoreItems={hasMoreItems}
          getNextPage={getNextPage}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default Trending;
