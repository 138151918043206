import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const LessonSummary = ({ summary }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8 items-center w-full px-20 pt-8">
      <div className="w-3/4 flex flex-col gap-2">
        {summary?.points?.map((item, index) => {
          const indexString = summary?.points.length > 1 ? `${index + 1}.` : "";
          return (
            <>
              <Typography
                gutterBottom
                variant="h7"
                component="div"
                style={{ display: "flex" }}
              >
                <span style={{ marginRight: "8px" }}>{indexString}</span>{" "}
                {/* Adjust margin as needed */}
                <span>{item.point}</span>
              </Typography>
              <ul className="ml-8 mb-4">
                {item.subPoints.map((subItem, subIndex) => {
                  return <li className="list-disc list-outside">{subItem}</li>;
                })}
              </ul>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default LessonSummary;
