import { createSlice } from "@reduxjs/toolkit";
import {
  getTrendingArticlesAsync,
  updateTrendingArticlesAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  all: [],
};

export const trendingArticlesSlice = createSlice({
  name: "trending",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrendingArticlesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTrendingArticlesAsync.fulfilled, (state, action) => {
        state.all = action.payload;
        state.isLoading = false;
      })
      .addCase(getTrendingArticlesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(updateTrendingArticlesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTrendingArticlesAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTrendingArticlesAsync.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default trendingArticlesSlice.reducer;
