import React from "react";

import { BiUser } from "react-icons/bi";
import { FaFacebook } from "react-icons/fa";
import { GiHumanPyramid } from "react-icons/gi";
import { FaBook } from "react-icons/fa6";
import {
  MdDashboard,
  MdOutlineGroups,
  MdOutlinePausePresentation,
  MdEventAvailable,
} from "react-icons/md";
import { IoIosSchool, IoMdSettings } from "react-icons/io";
import { MdPlayLesson } from "react-icons/md";
import { RiArticleFill } from "react-icons/ri";

export const links = [
  {
    title: "sidebar.dashboard",
    links: [
      {
        type: "route",
        name: "sidebar.dashboard",
        path: "/dashboard",
        icon: <MdDashboard />,
      },
      {
        type: "route",
        name: "Գլխավոր էջի կառավարում",
        path: "/trending",
        icon: <IoMdSettings size={22} />,
      },
    ],
  },
  {
    title: "sidebar.myPages",
    links: [
      {
        type: "route",
        name: "sidebar.profile",
        path: "/profile",
        icon: <BiUser />,
      },
      {
        type: "route",
        name: "sidebar.articles",
        path: "/articles",
        icon: <RiArticleFill />,
      },
    ],
  },
  {
    title: "sidebar.works",
    links: [
      // {
      //   type: "route",
      //   name: "sidebar.presentations",
      //   path: "/presentations",
      //   icon: <MdOutlinePausePresentation />,
      // },
      {
        type: "route",
        name: "sidebar.groups",
        path: "/groups",
        icon: <MdOutlineGroups />,
      },
      {
        type: "route",
        name: "sidebar.speakers",
        path: "/profile",
        icon: <BiUser />,
      },
      {
        type: "route",
        name: "sidebar.events",
        path: "/events",
        icon: <MdEventAvailable />,
      },
    ],
  },
  {
    title: "sidebar.common",
    links: [
      {
        type: "route",
        name: "sidebar.school",
        path: "/school",
        icon: <IoIosSchool />,
      },
      {
        type: "route",
        name: "sidebar.courses",
        path: "/courses",
        icon: <MdPlayLesson />,
      },
      {
        type: "route",
        name: "sidebar.lessons",
        path: "/lessons",
        icon: <MdPlayLesson />,
      },
      {
        type: "route",
        name: "sidebar.library",
        path: "/library",
        icon: <FaBook />,
      },
    ],
  },
  {
    title: "sidebar.socialNetworks",
    links: [
      {
        type: "route",
        name: "sidebar.mitaniq",
        path: "https://mitani.am/",
        icon: <GiHumanPyramid />,
      },
      {
        type: "route",
        name: "sidebar.facebook",
        path: "https://facebook.com",
        icon: <FaFacebook />,
      },
    ],
  },
];

export const dashboardItems = [
  {
    slug: "articles",
    type: "route",
    name: "sidebar.articles",
    path: "/articles",
    icon: <RiArticleFill color="red" size="40" />,
    color: "red",
  },
  {
    slug: "presentations",
    type: "route",
    name: "sidebar.presentations",
    path: "/presentations",
    icon: <MdOutlinePausePresentation color="orange" size="40" />,
    color: "orange",
  },
  {
    slug: "groups",
    type: "route",
    name: "sidebar.groups",
    path: "/groups",
    icon: <MdOutlineGroups color="green" size="40" />,
    color: "green",
  },
];
