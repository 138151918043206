import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  selectIsLoading,
  selectTrendingArticles,
} from "redux-toolkit/trending/selectors";
import {
  getTrendingArticlesAsync,
  updateTrendingArticlesAsync,
} from "redux-toolkit/trending/actions";
import { toast } from "react-toastify";

const useTrendingArticles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const trendingArticles = useSelector(selectTrendingArticles, shallowEqual);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (!trendingArticles || trendingArticles.length === 0) {
      dispatch(getTrendingArticlesAsync());
    }
  }, [dispatch, trendingArticles]);

  const updateTrendingArticles = useCallback(
    (id, data) => {
      toast.dismiss();
      toast.loading(t("Հրապարակվում է․․․"));
      dispatch(
        updateTrendingArticlesAsync({ id, data: { content: data } }),
      ).then((res) => {
        toast.dismiss();
        toast.success(t("Հաջողությամբ հրապարակվեց"));
        dispatch(getTrendingArticlesAsync());
      });
    },
    [dispatch, t],
  );

  return {
    trendingArticles,
    updateTrendingArticles,
    isLoading,
  };
};

export default useTrendingArticles;
