import { createSlice } from "@reduxjs/toolkit";
import {
  getCoursesAsync,
  getCourseByIdAsync,
  getNextCoursesAsync,
} from "./actions";

const initialState = {
  isLoading: true,
  current: null,
  all: [],
  last: null,
  count: 0,
};

export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCoursesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCoursesAsync.fulfilled, (state, action) => {
        state.all = action.payload.courses;
        state.last = action.payload.last;
        state.count = action.payload.totalCoursesCount;
        state.isLoading = false;
      })
      .addCase(getCoursesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getNextCoursesAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNextCoursesAsync.fulfilled, (state, action) => {
        state.all = [...state.all, ...action.payload.courses];
        state.last = action.payload.last;
        state.isLoading = false;
      })
      .addCase(getNextCoursesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.all = [];
      })
      .addCase(getCourseByIdAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCourseByIdAsync.fulfilled, (state, action) => {
        state.current = action.payload;
        state.isLoading = false;
      })
      .addCase(getCourseByIdAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.current = null;
      });
  },
});

export default coursesSlice.reducer;
