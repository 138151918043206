import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app-slice";
import articlesReducer from "redux-toolkit/articles/slice";
import eventsReducer from "redux-toolkit/events/slice";
import groupsReducer from "redux-toolkit/groups/slice";
import usersReducer from "redux-toolkit/users/slice";
import presentationsReducer from "redux-toolkit/presentations/slice";
import lessonsReducer from "redux-toolkit/lessons/slice";
import dashboardReducer from "redux-toolkit/dashboard/slice";
import coursesReducer from "redux-toolkit/courses/slice";
import trendingArticlesReducer from "redux-toolkit/trending/slice";

const combinedReducer = combineReducers({
  app: appReducer,
  articles: articlesReducer,
  groups: groupsReducer,
  users: usersReducer,
  presentations: presentationsReducer,
  lessons: lessonsReducer,
  dashboard: dashboardReducer,
  events: eventsReducer,
  courses: coursesReducer,
  trending: trendingArticlesReducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
