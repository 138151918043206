import React from "react";
import PropTypes from "prop-types";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Button, TextareaAutosize } from "@mui/material";
import useEditSummary from "./useEditSummary";

const SummaryForm = (props) => {
  const { currentSummary, setCurrentSummary } = props;
  const { t } = useTranslation();
  const { newPoints } = useEditSummary({
    currentSummary,
  });
  return (
    <div className="w-full flex items-center justify-center bg-gray-100 group">
      <div className="h-full w-full bg-gray-100 p-4">
        <div className="flex justify-between border-b pb-4 mb-4">
          <span size="16" font="normal">
            {t("courses.summary.summaryPoints")}
          </span>
        </div>
        {currentSummary?.points.map((item, pointIndex) => {
          return (
            <div key={pointIndex} className="flex flex-col mt-4">
              <div className="flex">
                <div className="w-full">
                  <TextareaAutosize
                    className="w-full border-2 border-gray-300 rounded-md p-2"
                    defaultValue={item.point}
                    onChange={(e) => {
                      newPoints[pointIndex].point = e.target.value;
                      setCurrentSummary({
                        ...currentSummary,
                        points: newPoints,
                      });
                    }}
                    placeholder={`${t("courses.summary.point")} ${
                      pointIndex + 1
                    }`}
                  />
                </div>
                <div className="opacity-0 group-hover:opacity-100">
                  <Button
                    onClick={() => {
                      newPoints.splice(pointIndex, 1);
                      setCurrentSummary({
                        ...currentSummary,
                        points: newPoints,
                      });
                    }}
                  >
                    <MdDelete color="red" size={20} />
                  </Button>
                </div>
              </div>
              {currentSummary.points[pointIndex].subPoints.map(
                (item, subPointIndex) => {
                  const handleOnChange = (value) => {
                    newPoints[pointIndex].subPoints[subPointIndex] = value;
                    setCurrentSummary({ ...currentSummary, points: newPoints });
                  };
                  return (
                    <div
                      key={subPointIndex}
                      className="flex justify-between items-center mt-6 ml-16"
                    >
                      <div className="w-full">
                        <TextareaAutosize
                          className="w-full border-2 border-gray-300 rounded-md p-2"
                          value={
                            currentSummary.points[pointIndex].subPoints[
                              subPointIndex
                            ]
                          }
                          onChange={(e) => {
                            handleOnChange(e.target.value);
                          }}
                          placeholder={`${t("courses.summary.subPoint")} ${
                            subPointIndex + 1
                          }`}
                        />
                      </div>
                      <div className="opacity-0 group-hover:opacity-100">
                        <Button
                          onClick={() => {
                            newPoints[pointIndex].subPoints.splice(
                              subPointIndex,
                              1,
                            );
                            setCurrentSummary({
                              ...currentSummary,
                              points: newPoints,
                            });
                          }}
                        >
                          <MdDelete color="red" size={20} />
                        </Button>
                      </div>
                    </div>
                  );
                },
              )}
              <div className="flex justify-center mt-4">
                <Button
                  onClick={() => {
                    newPoints[pointIndex].subPoints.push("");
                    setCurrentSummary({ ...currentSummary, points: newPoints });
                  }}
                >
                  <IoMdAdd size={20} />
                  {t("courses.summary.addSubPoint")}
                </Button>
              </div>
            </div>
          );
        })}
        <div className="flex justify-center mt-8">
          <Button
            onClick={() => {
              newPoints.push({
                subPoints: [],
              });
              setCurrentSummary({ ...currentSummary, points: newPoints });
            }}
          >
            <IoMdAdd size={20} />
            {t("courses.summary.addPoint")}
          </Button>
        </div>
      </div>
    </div>
  );
};

SummaryForm.propTypes = {
  setCurrentSummary: PropTypes.func.isRequired,
};

export default SummaryForm;
